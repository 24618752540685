@import 'src/styles/variables';

$base: #333;
$red: #ff6469;
$border: #dedede;

$calendar-border: #dedede;
$calendar-saturday: #0ac;
$calendar-sunday: #f33;

@mixin safe-area-inset {
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
}

@mixin media($breakpoint) {
  $breakpoints: (
    'pc': 'all and (min-width: #{$breakpoint-pc})',
    'tab': 'all and (min-width: #{$breakpoint-tab})',
  );

  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@function layout-unit($value) {
  @return $value * $size-layout-unit;
}

@mixin opacity($opacity) {
  /* IE filter */
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
  -moz-opacity:$opacity;    /* Mozilla extension */
  -khtml-opacity:$opacity;  /* Safari/Conqueror extension */
  opacity:$opacity; /* CSS3 */
}

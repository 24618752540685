$size-layout-unit: 16px;
$size-content-width-pc: 1140px;

$breakpoint-pc: 940px;
$breakpoint-tab: 480px;

$color-brand: #ff6469;
$color-biz-brand: #0bd;
$color-white: #fff;
$color-black: #333;
$color-navy: #002E4E;
$color-background: #fafafa;
$color-border: #ddd;

$color-link: #02bfd3;
$color-cources-accepting: #00ccbc;
$color-cources-prepayment: #fcc63c;
$color-cources-onsite: #1e3c81;
$color-cources-require-acceptance: #ff6469;
$color-subscription: #00c8be;
$color-ticketbook: #bc88ff;

$gradient-brand: linear-gradient(to right, $color-brand, #ff875a);
$gradient-biz-brand: linear-gradient(to right, #00c8be, $color-biz-brand);

/* use in react-draft-wysiwyg */
$font_standard: $color-black;
$font_light: #777;
$white: #fff;
$gray: #777;
$border_gray: #dce1e6;
$bg_lightgray: #f0f2f4;
$coubic_blue: #00bbdd;
$coubic_blue_thicker: #0099bb;
$coubic_danger: #ff3333;
$coubic_warning: #eeaa00;
$coubic_success: #00cc99;

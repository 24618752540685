@keyframes closeWindow {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ril__outer {
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  background-color: rgba(0, 0, 0, 0.85);
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: none;
  margin: auto;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  margin: auto;
  padding: 40px 30px;
  border: none;
  opacity: 0.7;
  background-size: 12px 20px;
  cursor: pointer;
}

.ril__navButtons:hover {
  opacity: 1;
}

.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2) no-repeat center;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2220.5%22%20viewBox%3D%220%200%2012%2020.5%22%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M18.037-12.417%2C8.792-21.554a.975.975%2C0%2C0%2C1%2C0-1.392l9.245-9.137a1.456%2C1.456%2C0%2C0%2C1%2C2.041%2C0%2C1.415%2C1.415%2C0%2C0%2C1%2C0%2C2.016L12.169-22.25l7.909%2C7.815a1.417%2C1.417%2C0%2C0%2C1%2C0%2C2.017%2C1.456%2C1.456%2C0%2C0%2C1-2.041%2C0%22%20fill%3D%22%23fff%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2FclipPath%3E%3CclipPath%20id%3D%22b%22%3E%3Cpath%20d%3D%22M0%2C0H130V-44H0Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%20transform%3D%22translate(-8.5%2032.5)%22%20clip-path%3D%22url(%23a)%22%3E%3Cg%20clip-path%3D%22url(%23b)%22%3E%3Cpath%20d%3D%22M3.5-7h22V-37.5H3.5Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2) no-repeat center;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2220.5%22%20viewBox%3D%220%200%2012%2020.5%22%3E%3Cdefs%3E%3CclipPath%20id%3D%22a%22%3E%3Cpath%20d%3D%22M18.037-12.417%2C8.792-21.554a.975.975%2C0%2C0%2C1%2C0-1.392l9.245-9.137a1.456%2C1.456%2C0%2C0%2C1%2C2.041%2C0%2C1.415%2C1.415%2C0%2C0%2C1%2C0%2C2.016L12.169-22.25l7.909%2C7.815a1.417%2C1.417%2C0%2C0%2C1%2C0%2C2.017%2C1.456%2C1.456%2C0%2C0%2C1-2.041%2C0%22%20fill%3D%22%23fff%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2FclipPath%3E%3CclipPath%20id%3D%22b%22%3E%3Cpath%20d%3D%22M0%2C0H130V-44H0Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%20transform%3D%22translate(20.499%20-12)%20rotate(180)%22%20clip-path%3D%22url(%23a)%22%3E%3Cg%20clip-path%3D%22url(%23b)%22%3E%3Cpath%20d%3D%22M3.5-7h22V-37.5H3.5Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  flex: 0 1 auto;
  padding-right: 0;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  flex: 0 0 auto;
  padding-right: 20px;
  padding-left: 0;
}

.ril__toolbarItem {
  display: inline-block;
  max-width: 100%;
  padding: 0;
  overflow: hidden;
  color: #fff;
  font-size: 120%;
  line-height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  border: none;
  opacity: 0.7;
  cursor: pointer;
}

.ril__builtinButton:hover {
  opacity: 1;
}

.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  opacity: 0.5;
  cursor: default;
}

.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=') no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+') no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=') no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  position: relative;
  width: 60px;
  height: 60px;
}

.ril__loadingCirclePoint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  width: 11%;
  height: 30%;
  margin: 0 auto;
  animation: pointFade 800ms infinite ease-in-out both;
  border-radius: 30%;
  background-color: #fff;
}

.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}

.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}

.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}

.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}

.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}

.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}

.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}

.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}

.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}

.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}

.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}

.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}

.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}

.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}

.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}

.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}

.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}

.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}

.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}

.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
}

.button--default::after {
  content: '';
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.6, 0, 0.6, 0.6);
  transition-duration: 200ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: 0;
}

.button--default:not(:disabled):hover::after,
.button--default:not(:disabled):active::after {
  opacity: 1;
}

.icon-wrapper {
  font-size: 0;
  will-change: transform;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
